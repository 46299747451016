<template lang="pug">
  div(v-if="navigatorLanguage === 'es'")
    div.d-flex.fill-height(style="width: 100%; column-gap: 20px;")
      div.d-flex.flex-column.justify-start
        div(style="font-size: 14px;")
          p(style="font-size: 16px; color: #F93549; font-weight: 600; margin-bottom: 1em;") CÓMO SE CALCULA
          p(style="margin-bottom: 1em;") #[strong Paso 2]- GastroPricing realiza este ejercicio para todos los competidores y  confecciona un cuadro comparativo por familia a comparar.
          div.mb-4
            card_3_-s-v-g_1


  div(v-else-if="navigatorLanguage === 'en'")
    div.d-flex.fill-height(style="width: 100%; column-gap: 20px;")
      div.d-flex.flex-column.justify-start
        div(style="font-size: 14px;")
          p(style="font-size: 16px; color: #F93549; font-weight: 600; margin-bottom: 1em;") HOW IT IS CALCULATED
          p(style="margin-bottom: 1em;") #[strong Step 2]- Gastro Pricing carries out this exercise for all competitors and makes a comparative table per family.
          div.mb-4
            card_3_-s-v-g_1_en
</template>

<script>
import Card_3_SVG_1 from "./assets/Card_3_SVG_1";
import Card_3_SVG_1_en from "./assets/Card_3_SVG_1_en";

export default {
  name: "Card_3",
  components: {Card_3_SVG_1, Card_3_SVG_1_en}
}
</script>

<style scoped>

</style>
